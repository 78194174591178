@font-face {
    font-family: 'Gilroy Regular';
    src: url('../fonts/Gilroy-FREE/Gilroy-FREE/Gilroy-Regular.ttf');
}

@font-face {
    font-family: 'Gilroy Bold';
    src: url('../fonts/Gilroy-FREE/Gilroy-FREE/Gilroy-Bold.ttf');
}

* {
    margin: 0px;
    padding: 0px;
    font-family: 'Gilroy Regular';
}

/* width */
::-webkit-scrollbar {
    width: 10px;
    border-radius: 4px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

body,
html {
    max-height: 100vh;
    font-family: 'Gilroy Regular';
    font-size: 10px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Gilroy Bold';
    font-weight: normal;
}

/* margin */

.mb-4 {
    margin-bottom: 8%;
}

/* grid */

.grid-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.grid-4 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
}

.grid-3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
}

.sidenav {
    background: #3A377A;
    position: fixed;
    height: 100%;
    width: 20%;
}

.sidenav .sidenav-cover {
    /* overflow: scroll; */
    overflow-x: hidden;
    /* height: 400px; */
}

.main-display {
    background: #E5E3D6;
    height: 100vh;
    max-height: 100vh;
    overflow: scroll;
    width: calc(100% - 20%);
    margin-left: 20%;
    overflow-x: hidden;
    /* overflow: hidden; */
}

.sidenav ul li a {
    font-size: 1.4rem;
    padding: 15px 0px;
    padding-left: 30px;
}

/* .sidenav ul li:nth-child(2) {
    background: #57568F;
} */

.sidenav ul li a.active-link {
    background: #57568F;
}

.sidenav a {
    color: #fff;
    text-decoration: none;
    display: flex;
    align-items: center;
    grid-gap: 10px;
}

.sidenav svg {
    width: 9%;
    height: auto;
    fill: #fff;
}

.sidenav ul li a span {
    margin-bottom: -5px;
}

.sidenav .side-title {
    color: #fff;
    padding-left: 30px;
    height: 6rem;
    border-bottom: 1px solid #57568F;
    display: flex;
    align-items: center;
}

.sidenav .side-title h2 {
    margin-bottom: 0px;
    font-size: 2rem;
}

.topbar {
    height: 6rem;
    background: #fff;
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
}

.topbar .grid-flex {
    width: 100%;
    display: grid;
    grid-template-columns: 4fr 1fr;
    align-items: center;
    padding: 0px 20px;
}

.topbar .grid-flex-div li {
    grid-gap: 10px;
    display: inline-block;
}

.topbar .grid-flex-div {
    /* display: flex;
    align-items: center; */
    /* grid-gap: 10px; */
    /* display: inline-block; */
}

.topbar .grid-flex-div ul li {
    display: inline-block;
}

.topbar .grid-flex-div img {
    fill: #000000;
    stroke: #000000;
    width: 40%;
    height: auto;
}

.topbar ul {
    display: flex;
    align-items: center;
}

.topbar .avatar-sect {
    background: #DCCDFF;
    height: 40px;
    width: 40px;
    border-radius: 50%;
}

.bottom-bar {
    border-top: 1px solid #57568F;
    position: absolute;
    bottom: 0%;
    width: 100%;
    padding: 10px 0px;
}

.bottom-bar a {
    color: #fff;
    padding-left: 30px;
    text-decoration: none;
    font-size: 1.4rem;
}

.bottom-bar a:nth-child(1) {
    margin-bottom: 20px;
}

.sidenav h4 {
    border: 1px solid #57568F;
    margin-top: 30px;
    margin-left: 30px;
    width: max-content;
    padding: 10px 25px;
    color: #fff;
    font-size: 1.4rem;
    margin-bottom: 20px;
}

.main-display-content {
    padding: 40px 20px;
}

.dashboard-grid-data-2 {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 15px;
}

.content-box {
    background: #fff;
    padding: 10px 20px;
    border-radius: 4px;
    /* padding-top: 60px; */
}

.dashboard .content-box {
    padding-top: 0px;
    padding: 0px;
}

.dashboard .content-box.side-story {
    padding: 10px;
}

.dashboard .content-box.side-story p {
    font-size: 1.3rem;
    line-height: 1.6;
}

.dashboard .content-box.side-story p.bg-grey {
    background: #E5E3D6;
    border-radius: 3px;
    margin-top: 20px;
    padding: 5px 10px;
    text-align: center;
}

.dashboard .content-box .content-box-header {
    padding: 10px 10px;
}

.dashboard .content-box-body {
    padding: 0px 10px;
}

.dashboard .content-box-header a {
    font-size: 1.4rem;
}

.content-box.overview-summary .content-box-header .grid-3 {
    margin-top: 30px;
    margin-bottom: 10px;
}

.content-box.overview-summary .content-box-header .grid-3 p {
    font-size: 1.4rem;
}

.content-box.overview-summary .content-box-header .grid-3 h3 {
    font-size: 1.8rem;
    margin-bottom: 10px;
}

.dashboard :where(.css-dev-only-do-not-override-ed5zg0).ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>thead>tr>th {
    background: unset;
}

.main-content-design {
    background: #42BC00;
    padding: 10px 10px;
    border-radius: 4px;
}

.main-content-design p {
    line-height: 1.6;
    color: #fff;
    font-size: 1.3rem;
}

.main-content-design a {
    color: #fff;
    margin-top: 30px;
    font-size: 1.4rem;
    text-decoration: unset;
    padding-bottom: 5px;
    width: 30%;
    display: flex;
    align-items: center;
    grid-gap: 5px;
}

.main-content-design a svg {
    width: 20%;
    fill: #fff;
    margin-bottom: 0px;
}

.main-content-design h3 {
    color: #fff;
    font-size: 1.6rem;
    margin-bottom: 10px;
    border-top: 4px solid #fff;
    padding-top: 10px;
}

.content-box-header {
    padding: 10px 0px;
}

.content-box .content-box-header h3 {
    font-size: 1.6rem;
    /* margin-top: 30px; */
}

.dashboard .content-box .content-box-header h3 {
    margin-top: 0px;
}

.overview-summary-content {
    border-left: 2px solid #E5E3D6;
    padding-left: 15px;
}

.top-content-box-data {
    border-left: 5px solid #42BC00;
    padding-left: 20px;
}

.top-content-box-data p {
    font-size: 1.4rem;
}

.top-content-box-data h4 {
    font-size: 2.5rem;
    margin-bottom: 10px;
}

.main-display-content .grid-flex.page-header {
    align-items: flex-end;
    margin-bottom: 20px;
}

.main-display-content .grid-flex.page-header h3,
.dashboard .main-display .dashboard-title {
    font-size: 2rem;
}

.dashboard .main-display .dashboard-title {
    margin-bottom: 20px;
}

.ant-table-thead th.ant-table-cell {
    font-family: 'Gilroy Bold';
}

/* dashboard */
.bg-blue-content {
    background: #57568F;
    padding: 20px 10px;
    border-radius: 4px;
}

/* form */
.error-block {
    margin-bottom: 30px;
    margin-top: 20px;
}

form {
    margin-bottom: 30px;
}

.ant-select-selector {
    height: 5rem;
    padding-top: 40px;
}

.checkbox-grid {
    display: flex;
    grid-gap: 10px;
    flex-wrap: wrap;
}

:where(.css-dev-only-do-not-override-ed5zg0).ant-checkbox-wrapper+.ant-checkbox-wrapper {
    margin-left: 0px;
}

.form-group {
    display: block;
    margin-bottom: 20px;
}

.form-group-div {
    display: grid;
    grid-template-columns: 3fr 1fr;
}

.form-group-icon {
    height: 4.3rem;
    border: 1px solid #D9D9D9;
    border-left: none;
    border-radius: 0px 8px 8px 0px;
    display: flex;
    align-self: center;
    justify-content: center;
}

.form-group-div .ant-input {
    border-right: none;
    border-radius: 8px 0px 0px 8px;
}

.form-group-icon img {
    height: auto;
    width: 40%;
}

label {
    font-size: 1.4rem;
    margin-bottom: 0px;
    display: block;
}

.user-data label {
    margin-bottom: 0px;
    font-size: 1.3rem;
}

.error-message {
    color: red;
    font-size: 1.3rem;
}

.form-flex-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
}

.ant-select-selector {
    height: 5rem !important;
    padding-top: 10px !important;
}

/* button */

button {
    cursor: pointer;
    outline: none;
}

.button-grid {
    display: flex;
    grid-gap: 10px;
}

.form-signin-box {
    background: red;
    background: linear-gradient(rgba(0, 0, 0, .6), rgba(0, 0, 0, .6)), url('../images/adminbg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-signin-box .auth-box {
    background: #fff;
    width: 35%;
    padding: 20px 30px;
    border-radius: 4px;
    box-shadow: 0px 0px 3px 5px rgba(0, 0, 0, .1);
}

.form-signin-box .auth-box h3 {
    font-size: 1.8rem;
    margin-bottom: 10px;
}

.btn-blue {
    background: #3A377A;
    color: #fff;
    padding: 12px 35px;
    border: 1px solid #3A377A;
    font-size: 1.5rem;
    border-radius: 4px;
    text-decoration: none;
    display: block;
    margin-bottom: 0px;
}

.btn-default {
    background: transparent;
    color: #000;
    padding: 12px 35px;
    border: 1px solid #EFEFEF;
    font-size: 1.5rem;
    border-radius: 4px;
    text-decoration: none;
    display: block;
}

.btn-red {
    background: #F70101;
    color: #fff;
    padding: 12px 35px;
    border: 1px solid #F70101;
    font-size: 1.5rem;
    border-radius: 4px;
    text-decoration: none;
    display: block;
    margin-bottom: 0px;
}

.red {
    background: #F70101;
    color: #fff;
    padding: 5px 10px;
    border: 1px solid #F70101;
    font-size: 1.3rem;
    border-radius: 4px;
    margin-bottom: 0px;
}

.blue {
    background: #3A377A;
    color: #fff;
    padding: 5px 10px;
    border: 1px solid #3A377A;
    font-size: 1.3rem;
    border-radius: 4px;
    margin-bottom: 0px;
}

.green {
    background: #0E8127;
    color: #fff;
    padding: 5px 10px;
    border: 1px solid #0E8127;
    font-size: 1.3rem;
    border-radius: 4px;
    margin-bottom: 0px;
}

.grid-button {
    display: flex;
    grid-gap: 10px;
    margin-top: 30px;
}

.ant-modal-close {
    display: none;
}

.delete-button h3 {
    width: 90%;
}

.width-60 {
    width: 60%;
    display: block;
}

/* tags */

.currency {
    font-size: 1.2rem;
}

.tag-box {
    display: flex;
    grid-gap: 10px;
}

.tag-box {
    margin-bottom: 30px;
}

.tag {
    background: #D9D9D9;
    padding: 10px 15px;
    font-size: 1.3rem;
    border: 1px solid #D9D9D9;
    border-radius: 4px;
}

.tag.active {
    background: #3A377A;
    color: #fff;
}